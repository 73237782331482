import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  SelectItem,
} from "@nextui-org/react";
import { InputField, SelectField } from "../CustomComponents";
import { getErrorResponse, getYears } from "../../utils/functions";
import { useForm } from "react-hook-form";
import type { Applicant, UpdateApplicant } from "../../utils/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { UpdateApplicantSchema } from "../../utils/schemas";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "react-query";
import { patch } from "../../utils/api";
import { Campuses } from "../../assets/assets";
import { useLayoutEffect } from "react";

export default function UpdateSingle({
  show,
  onHide,
  applicant,
}: {
  show: boolean;
  onHide: () => void;
  applicant: Applicant;
}) {
  const client = useQueryClient();
  const { mutate, isLoading } = useMutation(patch<UpdateApplicant>, {
    onSuccess({ data }) {
      client
        .invalidateQueries("applicants")
        .then(() => {
          toast.success(data.message);
        })
        .finally(onHide);
    },
    onError(error) {
      const response = getErrorResponse(error);
      toast.error(response.message, { data: response.data });
    },
  });
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UpdateApplicant>({
    values: applicant,
    resolver: zodResolver(UpdateApplicantSchema),
  });

  function onSubmit(data: UpdateApplicant) {
    return mutate({
      data,
      endpoint: "/applicants",
      query: [{ key: "id", value: applicant.id }],
    });
  }

  useLayoutEffect(reset, [show, reset]);

  return (
    <Modal
      radius="sm"
      isOpen={show}
      onClose={onHide}
      isDismissable={false}
      hideCloseButton={true}
      backdrop="blur"
      isKeyboardDismissDisabled={true}
    >
      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader className="font-bold text-2xl">
          Edit Student Details
        </ModalHeader>

        <ModalBody>
          {/* <InputField
            autoFocus={true}
            variant="bordered"
            size="sm"
            inputMode="numeric"
            label="Application ID"
            placeholder="eg. 11111"
            isInvalid={Boolean(errors.id)}
            errorMessage={errors.id?.message}
            {...register("id", { required: true })}
          /> */}

          <InputField
            variant="bordered"
            label="Full name"
            size="sm"
            autoFocus={true}
            placeholder="eg. Mohamed Turay"
            isInvalid={Boolean(errors.full_name)}
            errorMessage={errors.full_name?.message}
            {...register("full_name", { required: true })}
          />

          <InputField
            variant="bordered"
            label="Program"
            size="sm"
            placeholder="eg. BSc. Computer Science"
            isInvalid={Boolean(errors.program)}
            errorMessage={errors.program?.message}
            {...register("program", { required: true })}
          />

          <SelectField
            radius="sm"
            variant="bordered"
            label="Academic year"
            isInvalid={Boolean(errors.academic_year)}
            errorMessage={errors.academic_year?.message}
            {...register("academic_year", { required: true })}
            items={getYears()}
          >
            {(item: any) => (
              <SelectItem key={item.value}>{item.label}</SelectItem>
            )}
          </SelectField>

          <SelectField
            radius="sm"
            variant="bordered"
            label="Campus"
            isInvalid={Boolean(errors.campus)}
            errorMessage={errors.campus?.message}
            {...register("campus", { required: true })}
            items={Campuses.map((c) => ({ label: c, value: c }))}
          >
            {(item: any) => (
              <SelectItem key={item.value}>{item.label}</SelectItem>
            )}
          </SelectField>
        </ModalBody>

        <ModalFooter>
          <Button
            onPress={onHide}
            isDisabled={isLoading}
            radius="sm"
            variant="ghost"
            className="font-semibold uppercase tracking-wider"
          >
            cancel
          </Button>
          <Button
            type="submit"
            color="success"
            radius="sm"
            isLoading={isLoading}
            className="font-semibold uppercase text-white tracking-wider"
          >
            update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
