import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { InputField } from "../CustomComponents";
import { useMutation } from "react-query";
import { patch } from "../../utils/api";
import { ChangeAdminPassword } from "../../utils/types";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ChangeAdminPasswordSchema } from "../../utils/schemas";

export default function ChangePassword({
  isOpen,
  onClose,
  title,
}: {
  title?: string;
  isOpen: boolean;
  onClose: () => void;
}) {
  const { mutate, isLoading } = useMutation(patch<ChangeAdminPassword>, {
    onSuccess({ data }) {
      if (data.is_success) {
        onClose();
      }

      toast(data.message, { type: data.is_success ? "success" : "error" });
    },
    onError(error) {
      if (error) {
        toast.error((error as any).response.data.message);
      }
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ChangeAdminPassword>({
    resolver: zodResolver(ChangeAdminPasswordSchema),
  });

  function onSubmit(data: ChangeAdminPassword) {
    mutate({
      data,
      endpoint: "/admins/change-password",
      query: [],
    });
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isKeyboardDismissDisabled={false}
      backdrop="blur"
      radius="sm"
      isDismissable={false}
    >
      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>Change password</ModalHeader>
        <ModalBody>
          {title && <span className="text-sm text-orange-500">{title}</span>}
          <InputField
            label="Old password"
            autoFocus={isOpen}
            variant="bordered"
            type="password"
            isInvalid={Boolean(errors.old_password)}
            errorMessage={errors.old_password?.message}
            {...register("old_password", { required: true })}
          />
          <InputField
            label="New password"
            variant="bordered"
            type="password"
            isInvalid={Boolean(errors.new_password)}
            errorMessage={errors.new_password?.message}
            {...register("new_password", { required: true })}
          />
          <InputField
            label="Confirm new password"
            variant="bordered"
            type="password"
            isInvalid={Boolean(errors.confirm_password)}
            errorMessage={errors.confirm_password?.message}
            {...register("confirm_password", { required: true })}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            onPress={onClose}
            radius="sm"
            variant="bordered"
            className="font-semibold uppercase text-xs"
          >
            Cancel
          </Button>
          <Button
            type="submit"
            radius="sm"
            variant="ghost"
            className="font-semibold uppercase text-xs"
            color="success"
            isLoading={isLoading}
          >
            Change
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
