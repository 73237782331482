import { useAuthContext } from "../../contexts/AuthContext";
import {
  Button,
  Card,
  CardBody,
  CircularProgress,
  Link,
  Spacer,
} from "@nextui-org/react";
import { Fetch } from "../../utils/api";
import ChartByCampus from "../../components/dashboard/ChartByCampus";
import { useMemo } from "react";
import { MdRefresh } from "react-icons/md";
import { greeting } from "../../utils/functions";

function Dashboard() {
  const { profile } = useAuthContext();
  const {
    data,
    isLoading: fetching,
    isFetching,
    isSuccess,
    refetch,
  } = Fetch({
    key: "dashboard",
    endpoint: `/dashboard`,
  });

  const isLoading = useMemo(() => {
    return fetching || isFetching;
  }, [fetching, isFetching]);

  const { chart, totals } = useMemo(() => {
    const res: {
      chart: [];
      totals: { activated: number; total: number; pending: number };
    } = { chart: [], totals: { activated: 0, pending: 0, total: 0 } };

    if (!isLoading && isSuccess) {
      const result = data?.data;

      if (result.is_success) {
        const { chart, ...total } = result.data;

        res.chart = chart;
        res.totals = total;
      }
    }

    return res;
  }, [isLoading, data, isSuccess]);

  return (
    <div className="p-8 overflow-auto">
      <div className="grid grid-cols-1 items-end sm:grid-cols-3 justify-between gap-2 mb-8">
        <div className="">
          <p className="text-slate-500 font-semibold tracking-wider">
            {greeting()},
          </p>
          <h1 className="font-bold text-3xl flex-1 ">{profile.full_name}!</h1>
        </div>
        <Spacer />
        <div className="flex sm:justify-end">
          <Button
            color="primary"
            radius="sm"
            variant="flat"
            startContent={<MdRefresh size={20} />}
            className="upper font-semibold"
            onPress={() => refetch()}
          >
            Refresh
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-7">
        <section id="totals" className="grid grid-col-1 sm:grid-cols-3 gap-4">
          <Card radius="sm" shadow="sm" className="h-full">
            <CardBody className="p-6">
              {isLoading ? (
                <CircularProgress />
              ) : (
                <div className="flex flex-col flex-1 justify-between">
                  <div className="mb-2">
                    <p className="text-slate-500 text-sm">
                      Total Students Uploaded
                    </p>
                    <h3 className="text-5xl font-bold">
                      {Intl.NumberFormat().format(totals.total)}
                    </h3>
                  </div>
                  <Link
                    href="/admin/students"
                    className="uppercase text-sm font-semibold"
                  >
                    <small>view details</small>
                  </Link>
                </div>
              )}
            </CardBody>
          </Card>

          <Card radius="sm" shadow="sm" className="h-full">
            <CardBody className="p-6">
              {isLoading ? (
                <CircularProgress />
              ) : (
                <div className="flex flex-col flex-1 justify-between">
                  <div className="mb-2">
                    <p className="text-slate-500">
                      Total Matriculation ID Assigned
                    </p>
                    <h3 className="text-5xl font-bold">
                      {Intl.NumberFormat().format(totals.activated)}
                    </h3>
                  </div>
                  <Link
                    href="/admin/generated-ids"
                    className="uppercase text-sm font-semibold"
                  >
                    <small>view details</small>
                  </Link>
                </div>
              )}
            </CardBody>
          </Card>

          <Card radius="sm" shadow="sm" className="h-full">
            <CardBody className="p-6">
              {isLoading ? (
                <CircularProgress />
              ) : (
                <div className="flex flex-col flex-1 justify-between">
                  <div className="mb-2">
                    <p className="text-slate-500">
                      Total Matriculation ID Pending
                    </p>
                    <h3 className="text-5xl font-bold">
                      {Intl.NumberFormat().format(totals.pending)}
                    </h3>
                  </div>
                  <Link
                    href="/admin/accounts"
                    className="uppercase text-sm font-semibold"
                  >
                    <small>view details</small>
                  </Link>
                </div>
              )}
            </CardBody>
          </Card>
        </section>

        <section id="charts">
          <Card radius="sm" shadow="sm">
            <CardBody className="flex flex-col p-6">
              <p className="text-lg font-bold text-slate-500">Data by campus</p>
              <div className="flex flex-1 justify-center items-center">
                <ChartByCampus data={chart} isLoading={isLoading} />
              </div>
            </CardBody>
          </Card>
        </section>
      </div>
    </div>
  );
}

export default Dashboard;
