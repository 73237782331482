import { useNavigate } from "react-router-dom";
import { DataTable, SelectField } from "./CustomComponents";
import {
  CircularProgress,
  Pagination,
  SelectItem,
  TableBody,
  TableColumn,
  TableHeader,
} from "@nextui-org/react";
import { useQueryContext } from "../contexts/QueryContext";
import { useEffect, useMemo, useRef, useState } from "react";
import { Fetch } from "../utils/api";
import Toolbar from "./Toolbar";
import Printer from "./shared/Printer";
import { MdHourglassEmpty, MdLock } from "react-icons/md";

type Column = {
  key: string;
  label: string;
  props?: any;
};

type PaginatedResponse<T> = {
  total: number;
  data: T[];
};

export default function DataGrid<T>({
  rows,
  title,
  queryKey,
  endpoint,
  columns,
  renderRow,
  setData,
  searchKeys,
}: {
  rows: T[];
  title?: string;
  queryKey: string;
  endpoint: string;
  columns: Column[];
  setData?: (data: T[]) => void;
  renderRow: (item: T) => JSX.Element;
  searchKeys?: { key: string; label: string }[];
}) {
  const printRef = useRef(null);
  const navigate = useNavigate();
  const { query, params } = useQueryContext();
  const [page, setPage] = useState<number>(Number(params.get("page")) || 1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    Number(params.get("limit")) || 20
  );
  const { data, isLoading, isFetching, isSuccess, isError, error } = Fetch({
    key: [queryKey, query],
    options: { keepPrevious: true, retry: 1 },
    endpoint: `${endpoint}?${query}`,
  });

  const errorMessage = useMemo(() => {
    if (isError) {
      const err = (error as any).response.data;

      return err.message;
    }
  }, [isError, error]);

  const result: PaginatedResponse<T> = useMemo(() => {
    if (!isLoading && isSuccess) {
      const fetched: PaginatedResponse<T> = data.data.data;

      return fetched;
    }

    return { total: 0, data: [] as T[] };
  }, [data, isLoading, isSuccess]);

  const selectedRowsPerPage = useMemo(
    () =>
      isSuccess && rowsPerPage > result.total ? result.total : rowsPerPage,
    [isSuccess, result, rowsPerPage]
  );

  function handleFieldChanges({
    nextRow,
    nextPage,
  }: {
    nextRow: number;
    nextPage: number;
  }) {
    if (nextRow !== rowsPerPage) {
      nextPage = 1;
    }

    const search_index = query.indexOf("search");
    let query_path = `?page=${nextPage || page}&limit=${
      nextRow || rowsPerPage
    }`;

    if (search_index > -1) {
      query_path += `&${query.slice(search_index)}`;
    }

    setPage(nextPage);
    setRowsPerPage(nextRow);

    navigate({ search: query_path });
  }

  function handleSearch(search: { key: string; value?: any }) {
    if (search.value.length > 0) {
      return navigate({
        search: `?page=1&limit=${rowsPerPage}&search[${search.key}]=${search.value}`,
      });
    }

    return navigate({ search: `?page=${page}&limit=${rowsPerPage}` });
  }

  const dataTable = (
    <DataTable ref={printRef}>
      <TableHeader columns={columns}>
        {(column) => (
          <TableColumn key={column.key} {...column.props}>
            {column.label}
          </TableColumn>
        )}
      </TableHeader>
      <TableBody
        loadingContent={<CircularProgress />}
        isLoading={isLoading || isFetching}
        emptyContent={
          <div
            className={`flex flex-col items-center ${
              isError ? "text-rose-400" : ""
            }`}
          >
            {true ? <MdLock size={48} /> : <MdHourglassEmpty size={48} />}
            {isError ? errorMessage : "No record found"}
          </div>
        }
        items={rows}
      >
        {renderRow}
      </TableBody>
    </DataTable>
  );

  useEffect(() => {
    if (setData) {
      setData(result.data);
    }
  }, [result.data, setData]);

  return (
    <>
      <Toolbar
        onSearch={handleSearch}
        searchKeys={(
          searchKeys ?? columns.filter((col) => !Boolean(col?.props))
        ).filter((key) => key.label.length > 0)}
      >
        <Printer
          title={title}
          data={result.data}
          printComponent={dataTable}
          isDisabled={result.data.length <= 0}
        />
      </Toolbar>

      {dataTable}

      <div className="flex justify-end gap-6 px-8 mt-3">
        <div className="w-fit">
          <SelectField
            labelPlacement="outside-left"
            label="Rows per page"
            className="items-center w-[12em]"
            classNames={{
              label: ["text-sm", "w-[20em]"],
            }}
            radius="sm"
            selectedKeys={[selectedRowsPerPage]}
            disallowEmptySelection={true}
            items={[
              { label: "20", value: 20 },
              { label: "50", value: 50 },
              { label: "100", value: 100 },
              { label: "500", value: 500 },
              { label: "All", value: result.total },
            ].filter(
              (item) => item.value < result.total || item.label === "All"
            )}
            onChange={({ target }: any) =>
              handleFieldChanges({
                nextPage: page,
                nextRow: Number(target.value),
              })
            }
          >
            {(item: any) => (
              <SelectItem key={item.value}>{item.label}</SelectItem>
            )}
          </SelectField>
        </div>
        {isSuccess && (
          <Pagination
            page={page}
            showControls={true}
            initialPage={page}
            total={Math.ceil(result.total / rowsPerPage)}
            onChange={(page) =>
              handleFieldChanges({ nextPage: page, nextRow: rowsPerPage })
            }
          />
        )}
      </div>
    </>
  );
}
