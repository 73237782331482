import { Input, Select, Table, extendVariants } from "@nextui-org/react";

export const InputField = extendVariants(Input, {
  defaultVariants: {
    radius: "sm",
    size: "sm",
    variant: "bordered",
  },
});

export const SelectField = extendVariants(Select, {
  defaultVariants: {
    radius: "sm",
    size: "sm",
    variant: "bordered",
  },
});

export const DataTable = extendVariants(Table, {
  variants: {
    color: {
      default: {
        base: [
          "overflow-auto",
          "px-6",
          "flex-1",
          "print:m-0",
          "print:p-0",
          "print:shadow-none",
          "print:border-none",
          "print:rounded-none",
        ],
      },
    },
  },
  defaultVariants: {
    color: "default",
    isHeaderSticky: "true",
    radius: "sm",
  },
});
