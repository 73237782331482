import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { InputField } from "../CustomComponents";
import { useMutation } from "react-query";
import { patch } from "../../utils/api";
import { ChangeAdminPassword } from "../../utils/types";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ChangeAdminPasswordSchema } from "../../utils/schemas";
import AdminPhoto from "./AdminPhoto";

export default function AdminProfile({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const { mutate, isLoading } = useMutation(patch<ChangeAdminPassword>, {
    onSuccess({ data }) {
      if (data.is_success) {
        onClose();
      }

      toast(data.message, { type: data.is_success ? "success" : "error" });
    },
    onError(error) {
      if (error) {
        toast.error((error as any).response.data.message);
      }
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ChangeAdminPassword>({
    resolver: zodResolver(ChangeAdminPasswordSchema),
  });

  function onSubmit(data: ChangeAdminPassword) {
    mutate({
      data,
      endpoint: "/admins/change-password",
      query: [],
    });
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      backdrop="blur"
      radius="sm"
      size="xl"
      hideCloseButton={true}
    >
      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader></ModalHeader>
        <ModalBody className="gap-3">
          <div className="grid grid-cols-1 sm:grid-cols-4 gap-2">
            <AdminPhoto />
            <fieldset className="col-span-1 sm:col-span-3 flex flex-col gap-2">
              <InputField
                placeholder="Full name"
                autoFocus={isOpen}
                variant="bordered"
                isInvalid={Boolean(errors.old_password)}
                errorMessage={errors.old_password?.message}
                {...register("old_password", { required: true })}
              />
              <InputField
                placeholder="Designation"
                variant="bordered"
                isInvalid={Boolean(errors.new_password)}
                errorMessage={errors.new_password?.message}
                {...register("new_password", { required: true })}
              />
              <InputField
                placeholder="Email"
                variant="bordered"
                isInvalid={Boolean(errors.new_password)}
                errorMessage={errors.new_password?.message}
                {...register("new_password", { required: true })}
              />
            </fieldset>
          </div>
          <InputField
            placeholder="Location"
            variant="bordered"
            isReadOnly={true}
            isInvalid={Boolean(errors.new_password)}
            errorMessage={errors.new_password?.message}
            {...register("new_password", { required: true })}
          />
          <InputField
            placeholder="Role"
            variant="bordered"
            isReadOnly={true}
            isInvalid={Boolean(errors.new_password)}
            errorMessage={errors.new_password?.message}
            {...register("new_password", { required: true })}
          />
          <InputField
            placeholder="Role"
            variant="bordered"
            isReadOnly={true}
            isInvalid={Boolean(errors.new_password)}
            errorMessage={errors.new_password?.message}
            {...register("new_password", { required: true })}
          />
          <InputField
            placeholder="Role"
            variant="bordered"
            isReadOnly={true}
            isInvalid={Boolean(errors.new_password)}
            errorMessage={errors.new_password?.message}
            {...register("new_password", { required: true })}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            onPress={onClose}
            radius="sm"
            variant="bordered"
            className="font-semibold uppercase text-xs"
          >
            Close
          </Button>
          <Button
            type="submit"
            radius="sm"
            variant="ghost"
            className="font-semibold uppercase text-xs"
            color="success"
            isLoading={isLoading}
          >
            update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
