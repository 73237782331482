import { Button, Link } from "@nextui-org/react";
import React, { useRef } from "react";
import { MdFileDownload, MdPrint } from "react-icons/md";
import { useReactToPrint } from "react-to-print";

export default function Printer<T>({
  title,
  printComponent,
  isDisabled,
  data,
}: Readonly<{
  isDisabled: boolean;
  title?: string;
  printComponent: React.ReactNode;
  data?: T[];
}>) {
  const printRef = useRef(null);
  const print = useReactToPrint({
    documentTitle: (title ?? "Sample").toLowerCase(),
  });

  return (
    <>
      {data && !isDisabled && (
        <Button
          title="Export"
          color="success"
          radius="sm"
          size="sm"
          isDisabled={isDisabled}
          variant="bordered"
          isIconOnly={true}
          as={Link}
          href="/template.csv"
          download={title?.replaceAll(" ", "_")?.toLocaleLowerCase()}
        >
          <MdFileDownload size={20} />
        </Button>
      )}

      <Button
        title="Print"
        color="secondary"
        isIconOnly={true}
        radius="sm"
        size="sm"
        isDisabled={isDisabled}
        variant="bordered"
        onPress={() => print(null, () => printRef.current!)}
      >
        <MdPrint size={20} />
      </Button>

      <div className={`p-8 hidden print:flex print:flex-col`} ref={printRef!}>
        <header className="flex justify-between items-center border-b border-slate-200 pb-2">
          <div className="">
            <h1 className="text-2xl font-black">
              Ernest Bai Koroma University of Science and Technology
            </h1>
            <h2 className="text-lg font-bold text-primary">
              Student Matriculation Number Generation System
            </h2>
          </div>

          <img src="/logo512.png" alt="app_logo" width={56} />
        </header>

        <section
          id="office"
          className="flex justify-between gap-2 bg-slate-100 p-1"
        >
          <div className="text-sm">
            <p className="font-bold text-lg">Registrar</p>
            <p>Oscar Oladipo Hughes</p>
            <p className="flex flex-col">
              <a href="mailto:oohughes@ebkustsl.edu.sl">
                oohughes@ebkustsl.edu.sl
              </a>
              <a href="tel:23278145933">(232) 78 145-933</a>
              <a href="tel:23277591751">(232) 77 591-751</a>
            </p>
          </div>
          <h3 className="uppercase font-bold text-3xl text-center">
            Office of the registrar
          </h3>
          <div className="text-sm">
            <p className="font-bold text-lg">University Secretariat</p>
            <p>Magburaka - Kono Highway</p>
            <p>Magburaka, Sierra Leone</p>
            <p>
              Web: <a href="https://ebkustsl.edu.sl">www.ebkustsl.edu.sl</a>
            </p>
            <p>
              Email:{" "}
              <a href="mailto:registrar@ebkustsl.edu.sl">
                registrar@ebkustsl.edu.sl
              </a>
            </p>
          </div>
        </section>

        <div className="mt-8">
          {title && <p className="text-black uppercase mb-4">{title}</p>}

          {printComponent}
        </div>
      </div>
    </>
  );
}
