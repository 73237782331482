import React from "react";
import { Outlet } from "react-router-dom";

export default function AuthLayout() {
  return (
    <main className="h-screen flex flex-col items-center justify-center bg-slate-100">
      <Outlet />
    </main>
  );
}
