import { Navigate, useSearchParams } from "react-router-dom";
import GenerateMatNumberForm from "../components/students/GenerateMatNumberForm";
import ActivateMatNumberForm from "../components/students/ActivateMatNumberForm";
import {
  Button,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
} from "@nextui-org/react";
import { MdArrowBack } from "react-icons/md";
import { ReactNode, useLayoutEffect, useState } from "react";
import type { Student as StudentType } from "../utils/types";

function Student() {
  const [params] = useSearchParams();
  const [data, setData] = useState<StudentType>();

  const paths: { [key: string]: ReactNode } = {
    freshmen: <GenerateMatNumberForm onSuccess={setData} />,
    "continuing-student": <ActivateMatNumberForm onSuccess={setData} />,
    null: <Navigate to="/" replace={true} />,
    undefined: <Navigate to="/" replace={true} />,
  };

  useLayoutEffect(() => setData(undefined), []);

  return (
    <>
      {params.has("q") && (
        <div className="mb-6 flex flex-col gap-2">
          <div className="">
            <Link href="/">
              <MdArrowBack className="inline-block" size={18} />
              <span className="text-xs font-semibold uppercase">Back</span>
            </Link>
            <h1 className="text-3xl font-black capitalize">
              {params.get("q")?.replaceAll("-", " ")}
            </h1>
          </div>
          <ul className="list-disc list-inside">
            <li className="list-item">
              Please contact the <b>University Registry</b> for your{" "}
              <strong>Password</strong>
            </li>

            <li className="list-item">
              Proceed by completing the fields below
            </li>
          </ul>
        </div>
      )}

      {paths[params.get("q") as string] ?? paths.undefined}

      <Modal
        radius="sm"
        backdrop="blur"
        isDismissable={false}
        isOpen={Boolean(data)}
        hideCloseButton={true}
        onClose={() => setData(undefined)}
        isKeyboardDismissDisabled={true}
      >
        <ModalContent>
          <ModalContent>
            <ModalBody>
              <div className="text-center">
                <p className="uppercase text-sm font-semibold">
                  your matriculation number is
                </p>
                <h4 className="text-6xl sm:text-7xl uppercase font-black tracking-widest text-center">
                  {data?.mat_number ?? 1234567}
                </h4>
              </div>

              <p className="justify-center text-lg font-bold text-slate-800 flex gap-2">
                <span className="truncate">
                  {data?.applicant.full_name ?? "Full Name"}
                </span>
                <span>|</span>
                <span className="truncate">
                  {data?.applicant.program ?? "Program"}
                </span>
              </p>
            </ModalBody>

            <ModalFooter className="justify-center">
              <Button
                radius="sm"
                size="sm"
                color="success"
                variant="bordered"
                className="font-semibold uppercase"
                onPress={() => setData(undefined)}
              >
                OK, Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </ModalContent>
      </Modal>
    </>
  );
}

export default Student;
