import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  User,
} from "@nextui-org/react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "../contexts/AuthContext";
import Sidebar from "../components/Sidebar";
import { useLayoutEffect, useMemo, useState } from "react";
import { MdMenu } from "react-icons/md";
import ChangePassword from "../components/admins/ChangePassword";
import { Routes } from "../assets/assets";
import { toast } from "react-toastify";
import AdminProfile from "../components/admins/AdminProfile";

export default function AdminLayout() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { profile, signingOut, signOut, refetch } = useAuthContext();
  const [showSideBar, setShowSideBar] = useState<boolean>(false);
  const [showModal, setShowModal] = useState({
    password: false,
    profile: false,
  });

  const authRoutes = useMemo(() => {
    if (Routes.find((r) => pathname.indexOf(r.url) > -1)) {
      return Routes.filter((r) => r.roles.indexOf(profile.role) > -1);
    }

    return [];
  }, [profile, pathname]);

  function handleShowModal(key: string, value: boolean) {
    setShowModal({ ...showModal, [key]: value });
  }

  useLayoutEffect(() => {
    if (authRoutes.length > 0) {
      const is_auth = authRoutes.find((r) => r.url === pathname);

      if (!Boolean(is_auth)) {
        toast.error("Unauthorised access");
        navigate("/admin", { replace: true });
      }
    }
  }, [pathname, authRoutes, navigate]);

  return (
    <>
      <div className="flex">
        <Sidebar
          profile={profile}
          isOpen={showSideBar}
          setIsOpen={setShowSideBar}
          routes={authRoutes}
        />

        <main className="flex-1 flex flex-col transition-all delay-100 bg-gray-50 overflow-hidden max-h-screen pb-6">
          <header className="flex bg-slate-50 py-[.9rem] px-6 shadow-sm">
            <div className="">
              <Button
                radius="sm"
                variant="light"
                className="sm:hidden"
                onPress={() => setShowSideBar(true)}
                isIconOnly={true}
              >
                <MdMenu size={28} />
              </Button>
            </div>

            <nav className="flex-1 flex justify-end">
              <Dropdown placement="bottom-end" radius="sm">
                <DropdownTrigger>
                  <User
                    as="button"
                    avatarProps={{
                      isBordered: true,
                      size: "sm",
                      src: profile.photo,
                    }}
                    className="transition-transform"
                    description={profile.full_name}
                    name={
                      <p className="uppercase font-semibold">
                        {profile.role.replaceAll("_", " ")}
                      </p>
                    }
                  />
                </DropdownTrigger>
                <DropdownMenu
                  aria-label="Profile Actions"
                  variant="flat"
                  disabledKeys={signingOut ? ["signOut"] : []}
                >
                  <DropdownItem key="user" className="h-14 gap-2">
                    <p className="font-semibold">Signed in as</p>
                    <p className="font-semibold">{profile.email}</p>
                  </DropdownItem>
                  <DropdownItem
                    key="profile"
                    onPress={() => handleShowModal("profile", true)}
                  >
                    My Profile
                  </DropdownItem>
                  <DropdownItem
                    key="password"
                    onPress={() => handleShowModal("password", true)}
                  >
                    Change Password
                  </DropdownItem>
                  <DropdownItem
                    onPress={signOut}
                    href="/auth"
                    key="signOut"
                    color="danger"
                  >
                    Log Out
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </nav>
          </header>

          <Outlet />
        </main>
      </div>

      <ChangePassword
        isOpen={profile.is_default_password || showModal.password}
        onClose={
          profile.is_default_password
            ? refetch
            : () => handleShowModal("password", false)
        }
        title={
          profile.is_default_password
            ? "Your password was reset to default! Please change it to continue."
            : undefined
        }
      />

      <AdminProfile
        isOpen={showModal.profile}
        onClose={() => handleShowModal("profile", false)}
      />
    </>
  );
}
