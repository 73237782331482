import { useState } from "react";
import PageHeader from "../../components/PageHeader";
import { Button, TableCell, TableRow } from "@nextui-org/react";
import { MdRefresh } from "react-icons/md";
import { Admin } from "../../utils/types";
import AdminAction from "../../components/admins/AdminAction";
import DataGrid from "../../components/DataGrid";
import { useQueryClient } from "react-query";
import InviteAdminModal from "../../components/admins/InviteAdmin";

function Admins() {
  const title = "System Admins";
  const client = useQueryClient();
  const [admins, setAdmins] = useState<Admin[]>();
  const [showModal, setShowModal] = useState({ invite: false });

  function handleShowModal(key: string, value: boolean) {
    setShowModal((pre) => ({ ...pre, [key]: value }));
  }

  return (
    <>
      <PageHeader title={title}>
        <Button
          color="primary"
          radius="sm"
          isDisabled={admins?.length === 0}
          variant="flat"
          startContent={<MdRefresh size={20} />}
          className="upper font-semibold"
          onPress={() => client.invalidateQueries("admins")}
        >
          Refresh
        </Button>
        <Button
          color="success"
          radius="sm"
          variant="flat"
          startContent={<MdRefresh size={20} />}
          className="upper font-semibold"
          onClick={() => handleShowModal("invite", true)}
        >
          Invite new
        </Button>
      </PageHeader>

      <DataGrid
        title={title}
        queryKey="admins"
        endpoint={`/admins/all`}
        rows={admins ?? []}
        searchKeys={[{ key: "email", label: "Email" }]}
        columns={[
          { key: "full_name", label: "Full name" },
          { key: "email", label: "Email" },
          { key: "designation", label: "Designation" },
          { key: "location", label: "Location" },
          { key: "role", label: "Role" },
          { key: "status", label: "Status" },
          { key: "", label: "" },
        ]}
        renderRow={(admin) => (
          <TableRow key={admin.id}>
            <TableCell>{admin.full_name}</TableCell>
            <TableCell>{admin.email}</TableCell>
            <TableCell>{admin.designation}</TableCell>
            <TableCell>{admin.location}</TableCell>
            <TableCell className="uppercase text-sm">
              {admin.role?.replaceAll("_", " ")}
            </TableCell>
            <TableCell>{admin.status}</TableCell>
            <TableCell className="relative justify-end">
              <AdminAction admin={admin} />
            </TableCell>
          </TableRow>
        )}
        setData={setAdmins}
      />

      <InviteAdminModal
        show={showModal.invite}
        onHide={() => handleShowModal("invite", false)}
      />
    </>
  );
}

export default Admins;
