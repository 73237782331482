import { z } from "zod";
import { Campuses } from "../assets/assets";

export const AdminLoginSchema = z.object({
  email: z
    .string()
    .min(1, "Email field is required")
    .email("Invalid email address"),
  password: z.string().min(1, "Password field is required"),
});

export const GetMatNumberSchema = z.object({
  applicant_id: z.coerce
    .number({
      required_error: "Application form ID is required",
    })
    .min(1, "Application form ID is required")
    .min(3, "Invalid application form ID"),
  password: z
    .string({ required_error: "Password is required" })
    .min(1, "Password is required")
    .min(6, "Password must be at least 6 characters"),
});

export const ActivateMatNumberSchema = GetMatNumberSchema.extend({
  mat_number: z.coerce
    .number({
      invalid_type_error: "Invalid matriculation number",
      required_error: "Matriculation number is required",
    })
    .min(1, "Matriculation number is required"),
});

export const ActivateAdminSchema = z
  .object({
    email: z
      .string()
      .min(1, "Email field is required")
      .email("Invalid email address"),
    password: z
      .string()
      .min(1, "Password field is required")
      .min(6, "Password must be at least 6 characters"),
    confirm_password: z.string().min(1, "Confirm password field is required"),
  })
  .refine(({ password, confirm_password }) => password === confirm_password, {
    message: "Passwords do not match",
    path: ["confirm_password"],
  });

export const AssignAccountSchema = z.object({
  applicant_id: z.coerce
    .number({
      required_error: "Application form ID is required",
    })
    .min(1, "Application form ID is required")
    .min(3, "Invalid application form ID"),
  mat_number: z.coerce
    .number({
      required_error: "Mat number is required",
      invalid_type_error: "Invalid value for Mat Number",
    })
    .min(2, "Application form ID is required"),
});

export const UpdateApplicantSchema = z.object({
  full_name: z
    .string({ required_error: "Full name is required" })
    .min(1, "Full name is required"),
  program: z
    .string({ required_error: "Program is required" })
    .min(1, "Program is required"),
  academic_year: z
    .string({ required_error: "Academic year is required" })
    .min(4, "Academic year is required"),
  campus: z.enum(["Makeni Campus", ...Campuses], {
    invalid_type_error: "Invalid value for campus",
    required_error: "Campus is required",
  }),
});

export const CreateApplicantSchema = UpdateApplicantSchema.extend({
  id: z.coerce
    .number({
      required_error: "Application form ID is required",
      invalid_type_error: "Invalid value for Application form ID",
    })
    .min(2, "Application form ID is required"),
});

export const InviteAdminSchema = z.object({
  email: z
    .string({
      required_error: "Email is required",
    })
    .min(2, "Email is required")
    .email("Invalid email address"),
  full_name: z
    .string({ required_error: "Full name is required" })
    .min(1, "Full name is required"),
  designation: z
    .string({ required_error: "Designation is required" })
    .min(1, "Designation is required"),
  location: z.enum(["University Secretariat", ...Campuses], {
    required_error: "Campus is required",
  }),
  role: z
    .string({ required_error: "Role is required" })
    .min(4, "Role is required"),
});

export const ChangeAdminPasswordSchema = z
  .object({
    old_password: z.string().min(1, "Old password is required"),
    new_password: z
      .string()
      .min(1, "New password is required")
      .min(6, "New password must be at least 6 characters"),
    confirm_password: z.string().min(1, "Confirm new password is required"),
  })
  .refine(
    ({ new_password, confirm_password }) => new_password === confirm_password,
    {
      message: "Passwords do not match",
      path: ["confirm_password"],
    }
  );
