import { Button } from "@nextui-org/react";
import { InputField } from "../CustomComponents";
import { useForm } from "react-hook-form";
import { GetMatNumber, Student } from "../../utils/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { GetMatNumberSchema } from "../../utils/schemas";
import { useMutation } from "react-query";
import { post } from "../../utils/api";
import { getErrorResponse } from "../../utils/functions";
import { useLayoutEffect } from "react";

export default function GenerateMatNumberForm({
  onSuccess,
}: {
  onSuccess: (data: Student) => void;
}) {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm<GetMatNumber>({
    resolver: zodResolver(GetMatNumberSchema),
  });
  const { data, mutate, isLoading, isSuccess, isError, error } = useMutation(
    post<GetMatNumber>
  );

  function onSubmit(data: GetMatNumber) {
    mutate({
      data,
      endpoint: "/students/generate",
    });
  }

  useLayoutEffect(() => {
    if (isSuccess) {
      onSuccess(data.data.data);
    } else if (isError) {
      const response = getErrorResponse(error);
      switch (response.code) {
        case 401:
          return setError("password", { message: response.message });
        case 404:
        default:
          return setError("applicant_id", { message: response.message });
      }
    }
  }, [isSuccess, data, isError, error, onSuccess, setError]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-3">
      <InputField
        size="lg"
        autoFocus={true}
        inputMode="numeric"
        label="Application form ID"
        isInvalid={Boolean(errors.applicant_id)}
        errorMessage={errors.applicant_id?.message}
        description="Provide the ID of your application form"
        {...register("applicant_id", { required: true })}
      />
      <InputField
        size="lg"
        type="password"
        label="Password"
        isInvalid={Boolean(errors.password)}
        errorMessage={errors.password?.message}
        description="Provide the password issued to you by registry"
        {...register("password", { required: true })}
      />

      <div className="flex justify-end">
        <Button
          size="lg"
          radius="sm"
          type="submit"
          color="success"
          isLoading={isSubmitting || isLoading}
          className="tracking-wider font-bold uppercase"
        >
          Submit
        </Button>
      </div>
    </form>
  );
}
