import { InputField, SelectField } from "./CustomComponents";
import { MdFilterAlt, MdSearch } from "react-icons/md";

import { ReactNode, useState } from "react";
import { SelectItem, Spacer } from "@nextui-org/react";

type SearchKey = {
  key: string;
  label: string;
};

export default function Toolbar({
  onSearch,
  searchKeys,
  children,
}: {
  searchKeys: SearchKey[];
  onSearch: (value: { key: string; value?: any }) => void;
  children?: ReactNode;
}) {
  const [search, setSearch] = useState(searchKeys[0]);

  return (
    <div className="grid grid-cols-1 gap-2 sm:grid-cols-3 lg:grid-cols-4 items-center p-6">
      <InputField
        type="search"
        size="sm"
        startContent={
          <label htmlFor="search">
            <MdSearch size={18} />
          </label>
        }
        onChange={({ target }) =>
          setTimeout(() => {
            onSearch({ key: search.key, value: target.value });
          }, 10)
        }
        id="search"
        placeholder={`Search ${search.label.toLowerCase()}...`}
        className="sm:col-span-2"
      />

      <Spacer className="hidden lg:block" />

      <div className="flex justify-end items-center gap-3">
        <SelectField
          size="sm"
          items={searchKeys}
          selectedKeys={[search.key]}
          classNames={{
            listbox: ["justify-end"],
          }}
          defaultSelectedKeys={[search.key]}
          startContent={<MdFilterAlt />}
          onChange={({ target }) =>
            setSearch(
              searchKeys.find((key) => key.key === target.value) ??
                searchKeys[0]
            )
          }
        >
          {(item: any) => (
            <SelectItem title={item.label} key={item.key} value={item.key}>
              {item.label}
            </SelectItem>
          )}
        </SelectField>

        {children}
      </div>
    </div>
  );
}
