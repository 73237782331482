import { Button, Link } from "@nextui-org/react";
import { ProfileType } from "../utils/types";
import { useLocation } from "react-router-dom";

type Route = { title: string; url: string; key: string; roles: string[] };

export default function Sidebar({
  isOpen,
  setIsOpen,
  profile,
  routes,
}: {
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  profile: ProfileType;
  routes: Route[];
}) {
  const { pathname } = useLocation();

  return (
    <aside
      className={`bg-gray-900 h-screen max-h-screen flex flex-col transition-all delay-75 w-[14rem] absolute sm:relative ${
        isOpen ? "ml-0" : "-ml-[14.1rem] md:ml-0"
      } z-50`}
    >
      <div className="px-3 py-2 flex gap-2 items-center text-primary bg-black border-b">
        <img
          src="/logo192.png"
          alt="app_logo"
          height={30}
          width={40}
          className="border bg-slate-100 border-primary p-[.1rem] rounded-full"
        />

        <div className="">
          <h4 className="font-black text-2xl">
            <abbr title="Ernest Bai Koroma University of Science and Technology, Sierra Leone">
              EBKUST - SL
            </abbr>
          </h4>

          <p className="text-xs text-slate-100 uppercase tracking-wider font-bold">
            <abbr title="Matriculation">mat</abbr> number system
          </p>
        </div>
      </div>

      <nav className="flex-1 mt-8 flex-col flex gap-1">
        {routes.map((route) => (
          <Button
            onPress={() => setIsOpen(false)}
            radius="none"
            fullWidth={true}
            as={Link}
            href={route.url}
            key={route.key}
            size="lg"
            className={`transition-all delay-150 justify-start font-semibold ${
              pathname === route.url
                ? "border-l-8 border-primary bg-primary bg-opacity-10 text-slate-50 "
                : "bg-transparent text-slate-300 "
            }`}
          >
            {route.title}
          </Button>
        ))}
      </nav>

      <footer className="mt-auto text-slate-400 px-3 py-2">
        <p className="text-center text-xs uppercase font-black">
          {profile.role?.toLocaleLowerCase()} account
        </p>
      </footer>
    </aside>
  );
}
