import { Modal, ModalBody, ModalContent, ModalHeader } from "@nextui-org/react";
import { Outlet } from "react-router-dom";

export default function MainLayout() {
  return (
    <main
      className="min-h-screen bg-cover bg-center bg-no-repeat bg-fixed bg-opacity-50"
      style={{ backgroundImage: "url('/images/bg-pavilion.jpg')" }}
    >
      <Modal
        size="lg"
        isOpen={true}
        backdrop="blur"
        isDismissable={false}
        hideCloseButton={true}
        placement="top-center"
        isKeyboardDismissDisabled={true}
        className="shadow-none rounded-none m-0 sm:rounded-xl sm:shadow-md"
      >
        <ModalContent className="min-h-screen sm:min-h-fit">
          <ModalHeader className="gap-2 bg-primary">
            <img
              src="/logo192.png"
              alt="app_logo"
              height={38}
              width={38}
              className="border bg-slate-100 border-primary p-[.1rem] rounded"
            />

            <div className="flex flex-col">
              <h4 className="font-black text-lg">
                <abbr
                  className="text-black"
                  title="Ernest Bai Koroma University of Science and Technology, Sierra Leone"
                >
                  EBKUST - SL
                </abbr>
              </h4>

              <p className="text-xs text-slate-100 uppercase tracking-wider font-bold">
                Matriculation number system
              </p>
            </div>
          </ModalHeader>

          <ModalBody className="py-6">
            <Outlet />
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* <Outlet /> */}
    </main>
  );
}
