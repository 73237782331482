import { ReactNode, createContext, useContext, useMemo } from "react";
import { Fetch, remove } from "../utils/api";
import {
  CircularProgress,
  Modal,
  ModalBody,
  ModalContent,
} from "@nextui-org/react";
import { Navigate } from "react-router-dom";
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  useMutation,
} from "react-query";
import { AxiosResponse } from "axios";
import { ProfileType } from "../utils/types";
import { getErrorMessage, unsetSessionData } from "../utils/functions";
import { toast } from "react-toastify";

type contextType = {
  profile: ProfileType;
  signOut: () => void;
  signingOut: boolean;
  refetch: <T>({
    options,
  }: {
    options?: RefetchOptions & RefetchQueryFilters<T>;
  }) => Promise<QueryObserverResult<AxiosResponse<any, any>, unknown>>;
};

export const AuthContext = createContext<contextType | any>({});

export default function AuthContextProvider({
  children,
}: Readonly<{ children: ReactNode }>) {
  const { data, isSuccess, isLoading, isError, refetch } = Fetch({
    endpoint: "/admins/profile",
    key: "profile",
  });
  const { mutate, isLoading: signingOut } = useMutation(remove, {
    onSuccess() {
      unsetSessionData("auth");
    },
    onError(error) {
      toast.error(getErrorMessage(error));
    },
  });

  const profile: ProfileType = useMemo(
    () => data?.data?.data as ProfileType,
    [data]
  );

  function signOut() {
    mutate({
      endpoint: "/admins/logout",
      query: [{ key: "id", value: profile.id }],
    });
  }

  return (
    <>
      <Modal
        isOpen={isLoading}
        hideCloseButton={true}
        className="shadow-none"
        placement="center"
        isKeyboardDismissDisabled={true}
        isDismissable={false}
      >
        <ModalContent className="bg-transparent">
          <ModalBody className="items-center">
            <CircularProgress />
          </ModalBody>
        </ModalContent>
      </Modal>

      {isSuccess ? (
        <AuthContext.Provider value={{ profile, refetch, signOut, signingOut }}>
          {children}
        </AuthContext.Provider>
      ) : (
        isError && <Navigate to="/auth" replace={true} />
      )}
    </>
  );
}

export function useAuthContext() {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("AuthContext only works inside of the AuthContextProvider");
  }

  return context;
}
