import { useLayoutEffect, useState } from "react";
import { Admin } from "../../utils/types";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from "@nextui-org/react";
import {
  MdDeleteForever,
  MdLock,
  MdLockOpen,
  MdLockReset,
  MdMoreVert,
} from "react-icons/md";
import { patch, remove } from "../../utils/api";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { IconType } from "react-icons";

type ModalDetails = {
  action: string;
  okText: string;
  callback: () => void;
};
export default function AdminAction({ admin }: { admin: Admin }) {
  const client = useQueryClient();
  const [modalProps, setModalProps] = useState<ModalDetails>();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { mutate: doPatch, isLoading: patching } = useMutation(patch<any>, {
    onSuccess({ data }) {
      if (data.is_success) {
        onClose();
        client.invalidateQueries("admins");
      }

      toast(data.message, { type: data.is_success ? "success" : "error" });
    },
    onError(error) {
      if (error) {
        toast.error((error as any).response.data.message);
      }
    },
  });
  const { mutate: doDelete, isLoading: deleting } = useMutation(remove, {
    onSuccess({ data }) {
      if (data.is_success) {
        onClose();
        client.invalidateQueries("admins");
      }

      toast(data.message, { type: data.is_success ? "success" : "error" });
    },
    onError(error) {
      if (error) {
        toast.error((error as any).response.data.message);
      }
    },
  });

  const actions: {
    key: string;
    props?: any;
    action?: () => void;
    Icon: IconType;
    children: string | JSX.Element;
  }[] = [
    // {
    //   key: "view",
    //   Icon: MdInfo,
    //   children: "View",
    // },
    {
      key: "reset-password",
      Icon: MdLockReset,
      children: "Reset password",
      action: () =>
        handleShowModal({
          action: "Reset admin password",
          okText: "Reset",
          callback: () =>
            doPatch({
              data: {},
              endpoint: "/admins/reset-password",
              query: [{ key: "id", value: admin.id }],
            }),
        }),
    },
    {
      props: { color: "warning" },
      key: "deactivate-activate",
      Icon: admin.is_active ? MdLock : MdLockOpen,
      children: `${admin.is_active ? "Deactivate" : "Activate"} admin`,
      action: () =>
        handleShowModal({
          action: (admin.is_active ? "Deactivate" : "Activate") + " admin",
          okText: admin.is_active ? "Deactivate" : "Activate",
          callback: () =>
            doPatch({
              endpoint: "/admins/status",
              data: {
                status: admin.is_active ? "deactivated" : "activated",
              },
              query: [{ key: "id", value: admin.id }],
            }),
        }),
    },
    {
      key: "delete",
      Icon: MdDeleteForever,
      children: "Delete admin",
      props: { color: "danger" },
      action: () =>
        handleShowModal({
          action: "Delete admin",
          okText: "Delete",
          callback: () =>
            doDelete({
              endpoint: "/admins",
              query: [{ key: "id", value: admin.id }],
            }),
        }),
    },
  ];

  function handleShowModal(data: ModalDetails) {
    setModalProps(data);
    onOpen();
  }

  useLayoutEffect(() => {
    if (!isOpen) {
      setModalProps(undefined);
    }
  }, [isOpen]);

  return (
    <>
      <Dropdown placement="bottom-end">
        <DropdownTrigger>
          <Button size="sm" variant="light" radius="sm" isIconOnly={true}>
            <MdMoreVert size={20} />
          </Button>
        </DropdownTrigger>

        <DropdownMenu
          items={actions}
          disabledKeys={
            admin.status === "invited"
              ? ["reset-password", "deactivate-activate"]
              : []
          }
        >
          {(item) => (
            <DropdownItem
              key={item.key}
              {...item.props}
              onPress={item.action}
              startContent={<item.Icon size={20} />}
            >
              {item.children}
            </DropdownItem>
          )}
        </DropdownMenu>
      </Dropdown>

      <Modal isOpen={isOpen}>
        <ModalContent>
          <ModalHeader className="pb-0">{modalProps?.action}?</ModalHeader>
          <ModalBody>
            <p className="text-sm">
              Are you sure you to{" "}
              <span className="text-semibold">
                {modalProps?.action.toLocaleLowerCase()}
              </span>
              ?
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={onClose}
              size="sm"
              radius="sm"
              className="font-semibold uppercase"
            >
              Cancel
            </Button>
            <Button
              size="sm"
              radius="sm"
              color="danger"
              isLoading={patching || deleting}
              className="font-semibold uppercase"
              onPress={() => modalProps?.callback()}
            >
              {modalProps?.okText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
