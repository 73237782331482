import { useState } from "react";
import PageHeader from "../../components/PageHeader";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  TableCell,
  TableRow,
} from "@nextui-org/react";
import { MdAdd, MdEdit, MdRefresh } from "react-icons/md";
import { Applicant } from "../../utils/types";
import DataGrid from "../../components/DataGrid";
import { useQueryClient } from "react-query";
import CreateSingle from "../../components/applicants/CreateSingle";
import BatchUpload from "../../components/applicants/BatchUpload";
import UpdateSingle from "../../components/applicants/UpdateSingle";

function Applicants() {
  const title = "List of Students";
  const client = useQueryClient();
  const [selected, setSelected] = useState<Applicant>();
  const [students, setStudents] = useState<Applicant[]>();
  const [showModal, setShowModal] = useState({
    single: false,
    upload: false,
    update: false,
  });

  function handleShowModal(
    key: string,
    value: boolean,
    defaultStudent?: Applicant
  ) {
    if (defaultStudent) {
      setSelected(defaultStudent);
    }

    setShowModal((prev) => ({ ...prev, [key]: value }));
  }

  return (
    <>
      <PageHeader title={title}>
        <Button
          color="primary"
          radius="sm"
          isDisabled={students?.length === 0}
          variant="flat"
          startContent={<MdRefresh size={20} />}
          className="upper font-semibold"
          onPress={() => client.invalidateQueries("applicants")}
        >
          Refresh
        </Button>

        <Dropdown placement="bottom-end">
          <DropdownTrigger>
            <Button
              color="success"
              radius="sm"
              variant="flat"
              startContent={<MdAdd size={20} />}
              className="upper font-semibold"
            >
              Add new
            </Button>
          </DropdownTrigger>

          <DropdownMenu>
            <DropdownItem onPress={() => handleShowModal("single", true)}>
              Create Single
            </DropdownItem>
            <DropdownItem onPress={() => handleShowModal("upload", true)}>
              Batch Upload
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </PageHeader>

      <DataGrid
        title={title}
        queryKey="applicants"
        endpoint="/applicants/all"
        rows={students ?? []}
        columns={[
          { key: "id", label: "APPID" },
          { key: "full_name", label: "Full name" },
          { key: "program", label: "Program" },
          { key: "campus", label: "Campus" },
          { key: "", label: "" },
        ]}
        renderRow={(student) => (
          <TableRow key={student.id}>
            <TableCell>{student.id}</TableCell>
            <TableCell>{student.full_name}</TableCell>
            <TableCell>{student.program}</TableCell>
            <TableCell>{student.campus}</TableCell>
            <TableCell className="justify-end flex">
              <Button
                size="sm"
                radius="sm"
                variant="ghost"
                className="gap-1"
                startContent={<MdEdit />}
                onPress={() => handleShowModal("update", true, student)}
              >
                Edit
              </Button>
            </TableCell>
          </TableRow>
        )}
        setData={setStudents}
      />

      <UpdateSingle
        applicant={selected!}
        show={showModal.update}
        onHide={() => handleShowModal("update", false)}
      />
      <CreateSingle
        show={showModal.single}
        onHide={() => handleShowModal("single", false)}
      />
      <BatchUpload
        show={showModal.upload}
        onHide={() => handleShowModal("upload", false)}
      />
    </>
  );
}

export default Applicants;
