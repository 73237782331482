import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Link,
} from "@nextui-org/react";
import { useForm } from "react-hook-form";
import { AdminLogin } from "../../utils/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { AdminLoginSchema } from "../../utils/schemas";
import { useMutation } from "react-query";
import { post } from "../../utils/api";
import { getErrorMessage, setSessionData } from "../../utils/functions";
import { toast } from "react-toastify";
import { InputField } from "../../components/CustomComponents";

function SignIn() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<AdminLogin>({
    resolver: zodResolver(AdminLoginSchema),
  });

  const { mutate, isLoading } = useMutation(post<AdminLogin>, {
    onSuccess({ data }) {
      if (data.is_success) {
        setSessionData("auth", data.data.token);
        window.location.href = "/admin";
      } else {
        toast.error(data.message);
      }
    },
    onError(error) {
      toast.error(getErrorMessage(error));
    },
  });

  function onSubmit(data: AdminLogin) {
    mutate({
      data,
      endpoint: "/admins/login",
    });
  }
  return (
    <div className="container h-full sm:h-fit sm:max-w-sm">
      <Card className="h-full rounded-none shadow-none p-2 sm:p-3 sm:shadow sm:rounded border-none">
        <CardHeader as={Link} href="/" className="gap-2 items-center">
          <img
            src="/logo192.png"
            alt="app_logo"
            height={38}
            width={38}
            className="border bg-slate-100 border-primary p-[.1rem] rounded"
          />

          <div className="flex flex-col">
            <h4 className="font-black text-lg">
              <abbr
                className="text-slate-500"
                title="Ernest Bai Koroma University of Science and Technology, Sierra Leone"
              >
                EBKUST - SL
              </abbr>
            </h4>

            <p className="text-xs text-slate-400 uppercase tracking-wider font-bold">
              Matriculation number system
            </p>
          </div>
        </CardHeader>

        <CardBody as="form" onSubmit={handleSubmit(onSubmit)} className="gap-3">
          <div className="mb-4">
            <h1 className="text-3xl font-black">Sign In</h1>
            <p className="text-sm text-slate-600">
              Enter your email and password to access your account
            </p>
          </div>
          <InputField
            autoFocus={true}
            label="Email"
            inputMode="email"
            isInvalid={Boolean(errors.email)}
            errorMessage={errors.email?.message}
            {...register("email", { required: true })}
          />

          <InputField
            type="password"
            label="Password"
            isInvalid={Boolean(errors.password)}
            errorMessage={errors.password?.message}
            {...register("password", { required: true })}
          />

          <Button
            variant="ghost"
            color="primary"
            radius="sm"
            size="lg"
            isLoading={isSubmitting || isLoading}
            className="font-bold uppercase tracking-wider mt-4"
            type="submit"
          >
            Sign In
          </Button>
        </CardBody>

        <CardFooter className="flex-col pt-0">
          <p>
            Just invited?{" "}
            <Link href="/auth/signup" className="font-semibold text-blue-500">
              Activate account!
            </Link>
          </p>
        </CardFooter>
      </Card>
    </div>
  );
}

export default SignIn;
