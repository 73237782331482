import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Link,
} from "@nextui-org/react";
import { useForm } from "react-hook-form";
import { ActivateAdmin } from "../../utils/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { ActivateAdminSchema } from "../../utils/schemas";
import { useMutation } from "react-query";
import { patch } from "../../utils/api";
import { toast } from "react-toastify";
import { InputField } from "../../components/CustomComponents";
import { useNavigate } from "react-router-dom";
import { useLayoutEffect } from "react";
import { getErrorResponse } from "../../utils/functions";

function SignUp() {
  const navigate = useNavigate();
  const {
    setError,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ActivateAdmin>({
    resolver: zodResolver(ActivateAdminSchema),
  });

  const { mutate, error, isError, isLoading } = useMutation(patch<any>, {
    onSuccess({ data }) {
      toast.success(data.message);
      setTimeout(() => {
        navigate("/auth", { replace: true });
      }, 1000);
    },
  });

  function onSubmit(data: ActivateAdmin) {
    const { email, ...rest } = data;
    mutate({
      data: rest,
      endpoint: "/admins/activate",
      query: [{ key: "email", value: email }],
    });
  }

  useLayoutEffect(() => {
    if (isError) {
      const response = getErrorResponse(error);
      switch (response.code) {
        case 404:
          setError("email", { message: response.message });
          break;
        default:
          toast.error(response.message);
          break;
      }
    }
  }, [error, isError, setError]);

  return (
    <div className="container h-full sm:h-fit sm:max-w-sm">
      <Card className="h-full rounded-none shadow-none p-2 sm:p-3 sm:shadow sm:rounded border-none">
        <CardHeader as={Link} href="/" className="gap-2 items-center">
          <img
            src="/logo192.png"
            alt="app_logo"
            height={38}
            width={38}
            className="border bg-slate-100 border-primary p-[.1rem] rounded"
          />

          <div className="flex flex-col">
            <h4 className="font-black text-lg">
              <abbr
                className="text-slate-500"
                title="Ernest Bai Koroma University of Science and Technology, Sierra Leone"
              >
                EBKUST - SL
              </abbr>
            </h4>

            <p className="text-xs text-slate-400 uppercase tracking-wider font-bold">
              Matriculation number system
            </p>
          </div>
        </CardHeader>

        <CardBody as="form" onSubmit={handleSubmit(onSubmit)} className="gap-3">
          <div className="mb-4">
            <h1 className="text-3xl font-black">Sign Up</h1>
            <p className="text-sm text-slate-600">
              Complete the form to activate your account and get started
            </p>
          </div>
          <InputField
            autoFocus={true}
            label="Enter your email"
            inputMode="email"
            isInvalid={Boolean(errors.email)}
            errorMessage={errors.email?.message}
            {...register("email", { required: true })}
          />

          <InputField
            type="password"
            label="Create new password"
            isInvalid={Boolean(errors.password)}
            errorMessage={errors.password?.message}
            {...register("password", { required: true })}
          />

          <InputField
            type="password"
            label="Confirm new password"
            isInvalid={Boolean(errors.confirm_password)}
            errorMessage={errors.confirm_password?.message}
            {...register("confirm_password", { required: true })}
          />

          <Button
            color="primary"
            radius="sm"
            size="lg"
            isLoading={isSubmitting || isLoading}
            className="font-bold text-slate-50 uppercase tracking-wider mt-4"
            type="submit"
          >
            Activate Account
          </Button>
        </CardBody>
        <CardFooter className="flex-col pt-0">
          <p>
            Already activated?{" "}
            <Link href="/auth" className="font-semibold text-blue-500">
              Sign in!
            </Link>
          </p>
        </CardFooter>
      </Card>
    </div>
  );
}

export default SignUp;
