import { ReactNode, createContext, useContext, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

type queryType = {
  query: string;
  params: URLSearchParams;
};
export const QueryContext = createContext<queryType>({
  query: "",
  params: new URLSearchParams(),
});

export default function QueryContextProvider({
  children,
}: Readonly<{ children: ReactNode }>) {
  const [params] = useSearchParams();
  const query = useMemo(() => {
    const _params: string[] = [];
    params.forEach((value, key) => _params.push(`${key}=${value}`));

    if (_params.length === 0) {
      _params.push("page=1", "limit=20");
    }

    return _params.join("&");
  }, [params]);

  return (
    <QueryContext.Provider value={{ query, params }}>
      {children}
    </QueryContext.Provider>
  );
}

export function useQueryContext() {
  const context = useContext(QueryContext);

  if (!context) {
    throw new Error(
      "QueryContext only works inside of the QueryContextProvider"
    );
  }

  return context;
}
