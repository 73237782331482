import { ReactNode } from "react";

export default function PageHeader({
  children,
  title,
}: Readonly<{ children: ReactNode; title: string }>) {
  return (
    <div className="px-6 pt-6 grid grid-cols-1 gap-1 sm:grid-cols-2 justify-between items-end">
      <h2 className="font-bold text-2xl">{title}</h2>
      <div className="flex gap-2 sm:justify-end">{children}</div>
    </div>
  );
}
