import { useState } from "react";
import PageHeader from "../../components/PageHeader";
import { Button, TableCell, TableRow } from "@nextui-org/react";
import { MdRefresh, MdUploadFile } from "react-icons/md";
import { Account } from "../../utils/types";
import PasswordButton from "../../components/accounts/PasswordButton";
import DataGrid from "../../components/DataGrid";
import { useQueryClient } from "react-query";
import ActivateSingle from "../../components/accounts/AssignSingle";
import BatchUpload from "../../components/accounts/BatchUpload";

function Accounts() {
  const title = "List of Accounts";
  const client = useQueryClient();
  const [accounts, setAccounts] = useState<Account[]>();
  const [showModal, setShowModal] = useState<{
    single: boolean;
    upload: boolean;
    selected?: Account;
  }>({ single: false, upload: false });

  function handleShowModal(key: string, value: boolean, selected?: Account) {
    setShowModal((prev) => ({ ...prev, [key]: value, selected }));
  }

  return (
    <>
      <PageHeader title={title}>
        <Button
          color="primary"
          radius="sm"
          isDisabled={accounts?.length === 0}
          variant="flat"
          startContent={<MdRefresh size={20} />}
          className="upper font-semibold"
          onPress={() => client.invalidateQueries("accounts")}
        >
          Refresh
        </Button>
        <Button
          onPress={() => handleShowModal("upload", true)}
          color="success"
          radius="sm"
          variant="flat"
          startContent={<MdUploadFile size={20} />}
          className="upper font-semibold"
        >
          Upload IDs
        </Button>
      </PageHeader>

      <DataGrid
        title={title}
        queryKey="accounts"
        endpoint="/applicants/accounts"
        rows={accounts ?? []}
        columns={[
          { key: "id", label: "APPID" },
          { key: "full_name", label: "Full name" },
          { key: "program", label: "Program" },
          {
            key: "is_activated",
            label: "Status",
            props: { className: "text-start" },
          },
          {
            key: "plain",
            label: "Password",
            props: { className: "text-start" },
          },
          {
            key: "",
            label: "",
            props: { className: "text-center" },
          },
        ]}
        renderRow={(account) => (
          <TableRow key={account.id}>
            <TableCell>{account.id}</TableCell>
            <TableCell>{account.full_name}</TableCell>
            <TableCell>{account.program}</TableCell>
            <TableCell>
              {account.is_activated ? "Assigned" : "Pending"}
            </TableCell>
            <TableCell>
              <PasswordButton
                key={`password_${account.id}`}
                data={account.plain}
              />
            </TableCell>
            <TableCell className="flex justify-end items-center">
              <Button
                variant="flat"
                onPress={() => handleShowModal("single", true, account)}
                size="sm"
                isDisabled={account.is_activated}
                className="font-semibold print:hidden"
              >
                Assign ID
              </Button>
            </TableCell>
          </TableRow>
        )}
        setData={setAccounts}
      />

      <ActivateSingle
        show={showModal.single}
        account={showModal.selected!}
        onHide={() => handleShowModal("single", false)}
      />
      <BatchUpload
        show={showModal.upload}
        onHide={() => handleShowModal("upload", false)}
      />
    </>
  );
}

export default Accounts;
