import { useEffect, useState } from "react";

export default function PasswordButton({ data }: { data: string }) {
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setShow(false);
      }, 10000);
    }
  }, [show]);

  return (
    <>
      <button
        onClick={() => setShow((pre) => !pre)}
        className="font-semibold uppercase print:hidden"
      >
        {show ? data : <small>show</small>}
      </button>

      <span className="hidden print:block">{data}</span>
    </>
  );
}
