import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Select,
  SelectItem,
} from "@nextui-org/react";
import { InputField } from "../CustomComponents";
import { useForm } from "react-hook-form";
import type { InviteAdmin } from "../../utils/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { InviteAdminSchema } from "../../utils/schemas";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "react-query";
import { Fetch, post } from "../../utils/api";
import { useEffect, useLayoutEffect, useMemo } from "react";
import { Campuses } from "../../assets/assets";
import { getErrorResponse } from "../../utils/functions";

export default function InviteAdminModal({
  show,
  onHide,
}: {
  show: boolean;
  onHide: () => void;
}) {
  const client = useQueryClient();
  const { mutate, error, isError, isLoading } = useMutation(post<InviteAdmin>, {
    onSuccess({ data }) {
      client
        .invalidateQueries("admins")
        .then(() => {
          toast.success(data.message);
        })
        .finally(onHide);
    },
  });
  const {
    register,
    setError,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<InviteAdmin>({
    resolver: zodResolver(InviteAdminSchema),
  });
  const { data: _roles, isSuccess: hasRoles } = Fetch({
    endpoint: "/admins/roles",
    key: "roles",
  });
  const roles = useMemo(() => {
    if (hasRoles) {
      return _roles.data.data;
    }
    return [];
  }, [_roles, hasRoles]);

  function onSubmit(data: InviteAdmin) {
    return mutate({
      data,
      endpoint: "/admins",
    });
  }

  useEffect(() => {
    if (isError) {
      const response = getErrorResponse(error);
      switch (response.code) {
        case 409:
          setError("email", { message: response.message });
          break;
        default:
          toast.error(response.message, { data: response.data });
          break;
      }
    }
  }, [error, isError, setError]);

  useLayoutEffect(reset, [show, reset]);

  return (
    <Modal
      radius="sm"
      isOpen={show}
      onClose={onHide}
      isDismissable={false}
      hideCloseButton={true}
      backdrop="blur"
      isKeyboardDismissDisabled={true}
    >
      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader className="text-2xl font-bold">Invite Admin</ModalHeader>

        <ModalBody>
          <InputField
            size="sm"
            autoFocus={true}
            inputMode="email"
            variant="bordered"
            label="Staff Email"
            isInvalid={Boolean(errors.email)}
            errorMessage={errors.email?.message}
            {...register("email", { required: true })}
          />

          <InputField
            size="sm"
            variant="bordered"
            label="Full name"
            isInvalid={Boolean(errors.full_name)}
            errorMessage={errors.full_name?.message}
            {...register("full_name", { required: true })}
          />

          <InputField
            size="sm"
            variant="bordered"
            label="Designation"
            isInvalid={Boolean(errors.designation)}
            errorMessage={errors.designation?.message}
            {...register("designation", { required: true })}
          />

          <Select
            size="sm"
            radius="sm"
            variant="bordered"
            label="Campus"
            isInvalid={Boolean(errors.location)}
            errorMessage={errors.location?.message}
            {...register("location", { required: true })}
            items={[...Campuses, "University Secretariat"].map((c) => ({
              label: c,
              key: c,
            }))}
          >
            {(campus: any) => (
              <SelectItem key={campus.key}>{campus.label}</SelectItem>
            )}
          </Select>

          <Select
            size="sm"
            radius="sm"
            variant="bordered"
            label="Role"
            isInvalid={Boolean(errors.role)}
            errorMessage={errors.role?.message}
            {...register("role", { required: true })}
            items={roles}
          >
            {(role: any) => (
              <SelectItem key={role.key}>{role.label}</SelectItem>
            )}
          </Select>
        </ModalBody>

        <ModalFooter>
          <Button
            onPress={onHide}
            isDisabled={isLoading || isSubmitting}
            radius="sm"
            variant="ghost"
            className="font-semibold uppercase tracking-wider"
          >
            cancel
          </Button>
          <Button
            type="submit"
            color="success"
            radius="sm"
            isLoading={isLoading || isSubmitting}
            className="font-semibold uppercase text-white tracking-wider"
          >
            Invite
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
