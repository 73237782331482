import { useState } from "react";
import PageHeader from "../../components/PageHeader";
import { Button, TableCell, TableRow } from "@nextui-org/react";
import { MdRefresh } from "react-icons/md";
import { Log } from "../../utils/types";
import DataGrid from "../../components/DataGrid";
import { useQueryClient } from "react-query";

function Logs() {
  const title = "System Logs";
  const client = useQueryClient();
  const [logs, setLogs] = useState<Log[]>();

  return (
    <>
      <PageHeader title={title}>
        <Button
          color="primary"
          radius="sm"
          isDisabled={logs?.length === 0}
          variant="flat"
          startContent={<MdRefresh size={20} />}
          className="upper font-semibold"
          onPress={() => client.invalidateQueries("logs")}
        >
          Refresh
        </Button>
      </PageHeader>

      <DataGrid
        title={title}
        queryKey="logs"
        endpoint="/logs"
        rows={logs ?? []}
        searchKeys={[{ key: "initiated_by", label: "initiator" }]}
        columns={[
          { key: "action", label: "Action" },
          { key: "initiated_by", label: "Initiator" },
          { key: "status", label: "Status" },
          { key: "role", label: "Role" },
          {
            key: "date",
            label: "Date",
            props: { className: "text-end" },
          },
        ]}
        renderRow={(log) => (
          <TableRow key={log.id}>
            <TableCell>{log.action}</TableCell>
            <TableCell>{log.initiated_by}</TableCell>
            <TableCell>{log.status}</TableCell>
            <TableCell>{log.initiator_type}</TableCell>
            <TableCell className="flex justify-end">
              {log.initiated_at.toLocaleString()}
            </TableCell>
          </TableRow>
        )}
        setData={setLogs}
      />
    </>
  );
}

export default Logs;
