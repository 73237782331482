import { Route, Routes, useNavigate } from "react-router-dom";
import { NextUIProvider } from "@nextui-org/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SignIn from "./pages/auth/SignIn";
import AuthLayout from "./layouts/AuthLayout";
import SignUp from "./pages/auth/SignUp";
import Dashboard from "./pages/admin/Dashboard";
import AuthContextProvider from "./contexts/AuthContext";
import AdminLayout from "./layouts/AdminLayout";
import Logs from "./pages/admin/Logs";
import Admins from "./pages/admin/Admins";
import Applicants from "./pages/admin/Applicants";
import Accounts from "./pages/admin/Accounts";
import Students from "./pages/admin/Students";
import Index from "./pages/Index";
import MainLayout from "./layouts/MainLayout";
import Student from "./pages/Student";

function App() {
  const navigate = useNavigate();
  return (
    <NextUIProvider navigate={navigate}>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Index />} />
          <Route path="student" element={<Student />} />
        </Route>

        <Route path="/auth" element={<AuthLayout />}>
          <Route index element={<SignIn />} />
          <Route path="signup" element={<SignUp />} />
        </Route>

        <Route
          path="/admin"
          element={
            <AuthContextProvider>
              <AdminLayout />
            </AuthContextProvider>
          }
        >
          <Route index element={<Dashboard />} />
          <Route path="generated-ids" element={<Students />} />
          <Route path="accounts" element={<Accounts />} />
          <Route path="students" element={<Applicants />} />
          <Route path="admins" element={<Admins />} />
          <Route path="logs" element={<Logs />} />
          <Route path="*" element={<>Route Not found</>} />
        </Route>

        <Route path="*" element={<>Not found</>} />
      </Routes>

      <ToastContainer
        theme="colored"
        position="top-center"
        hideProgressBar={true}
      />
    </NextUIProvider>
  );
}

export default App;
