import { CircularProgress } from "@nextui-org/react";
import Chart from "react-google-charts";
import { useMemo } from "react";

export default function ChartByCampus({
  data,
  isLoading,
}: {
  isLoading: boolean;
  data: { campus: string; activated: number; pending: number }[];
}) {
  const charData = useMemo(() => {
    const res: any[] = [];
    if (data.length > 0) {
      data.forEach((d) => {
        res.push([d.campus, Number(d.activated), Number(d.pending)]);
      });
    }
    return [["Campus", "Activated", "Pending"], ...res];
  }, [data]);

  return isLoading ? (
    <CircularProgress />
  ) : data.length > 0 ? (
    <Chart
      chartType="ColumnChart"
      width="100%"
      height="440px"
      data={charData}
      loader={<CircularProgress />}
      className="m-0 p-0"
      options={{
        curveType: "function",
        legend: { position: "bottom" },
      }}
    />
  ) : (
    <p className="text-lg text-slate-500">No data found</p>
  );
}
