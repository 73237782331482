import axios, { AxiosResponse } from "axios";
import { useQuery } from "react-query";
import { getSessionData } from "./functions";

const baseURL = process.env.NODE_ENV === "development" ? "" : "/backend";
const token = getSessionData("auth")?.data;

const api = axios.create({
  baseURL,
});

type postType<T> = {
  endpoint: string;
  data: T;
};

type getType = {
  endpoint: string;
  key: string | any[];
  options?: any;
};

type patchType<T> = {
  query: { key: string; value: any }[];
  endpoint: string;
  data: T;
};

type deleteType = {
  query: { key: string; value: any }[];
  endpoint: string;
};

async function get(endpoint: string): Promise<AxiosResponse<any, Response>> {
  return api.get(
    endpoint,
    token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : undefined
  );
}

export function Fetch({ endpoint, key, options }: getType) {
  const fetch = () => get(endpoint);

  return useQuery(key, fetch, {
    ...options,
    staleTime: 60,
    refetchOnWindowFocus: false,
  });
}

export async function post<T>({
  endpoint,
  data,
}: postType<T>): Promise<AxiosResponse<any, Response>> {
  return api.post(endpoint, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  });
}

export async function upload<T>({
  endpoint,
  data,
}: postType<T>): Promise<AxiosResponse<any, Response>> {
  return api.post(
    endpoint,
    data,
    token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : undefined
  );
}

export async function patch<T>({
  query,
  endpoint,
  data,
}: patchType<T>): Promise<AxiosResponse<any, Response>> {
  const query_str = query.map((q) => `${q.key}=${q.value}`);
  return api.patch(`${endpoint}?${query_str.join("&")}`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  });
}

export async function remove({
  query,
  endpoint,
}: deleteType): Promise<AxiosResponse<any, Response>> {
  const query_str = query.map((q) => `${q.key}=${q.value}`);
  return api.delete(`${endpoint}?${query_str.join("&")}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  });
}
