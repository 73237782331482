import {
  Button,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { useLayoutEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { upload } from "../../utils/api";
import { useForm } from "react-hook-form";
import { InputField } from "../CustomComponents";
import { getErrorResponse } from "../../utils/functions";

export default function BatchUpload({
  show,
  onHide,
}: {
  show: boolean;
  onHide: () => void;
}) {
  const client = useQueryClient();
  const [file, setFile] = useState<File>();
  const { mutate, isLoading } = useMutation(upload<any>, {
    onSuccess({ data }) {
      if (data.is_success) {
        toast.success(data.message);
        client.invalidateQueries("accounts").finally(onHide);
      } else {
        toast.error(data.message);
      }
    },
    onError(error) {
      const response = getErrorResponse(error);
      toast.error(response.message, { data: response.data });
    },
  });
  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<{ academic_year: string }>();

  function onSubmit(data: { academic_year: string }) {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("academic_year", data.academic_year);

      return mutate({
        data: formData,
        endpoint: "/students/upload",
      });
    }

    toast.error("Please select a file to upload");
  }

  useLayoutEffect(() => {
    if (!show) {
      setFile(undefined);
    }

    return reset;
  }, [show, reset]);

  return (
    <Modal
      radius="sm"
      isOpen={show}
      onClose={onHide}
      isDismissable={false}
      hideCloseButton={true}
      backdrop="blur"
      isKeyboardDismissDisabled={true}
    >
      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader className="text-2xl font-bold">
          Batch Assign IDs
        </ModalHeader>

        <ModalBody>
          <InputField
            onChange={(e: any) =>
              e.target.files ? setFile(e.target.files[0]) : undefined
            }
            radius="sm"
            type="file"
            label="Upload file"
            accept=".csv"
            labelPlacement="outside"
            variant="bordered"
            size="md"
            classNames={{
              innerWrapper: ["mt-3"],
            }}
            placeholder="Select a file to upload"
            description={
              <>
                <span>Don't have a file? </span>
                <Link
                  href="/templates/ids.csv"
                  target="_blank"
                  rel="no-referrer"
                  download="batch_assign_ids_template"
                  className="text-blue-500 underline"
                >
                  download template
                </Link>
              </>
            }
          />

          <p className="text-sm text-orange-600">
            <em>
              By clicking the upload button below, you agree to assign the
              provided Mat Numbers to their respective APPID as specified in the
              file.
            </em>
          </p>
        </ModalBody>

        <ModalFooter>
          <Button
            onPress={onHide}
            isDisabled={isLoading || isSubmitting}
            radius="sm"
            variant="ghost"
            className="font-semibold uppercase tracking-wider"
          >
            cancel
          </Button>
          <Button
            type="submit"
            color="success"
            radius="sm"
            isDisabled={!Boolean(file)}
            isLoading={isLoading || isSubmitting}
            className="font-semibold uppercase text-white tracking-wider"
          >
            Upload
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
