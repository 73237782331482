import  { useLayoutEffect, useState } from "react";
import { Student } from "../../utils/types";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from "@nextui-org/react";
import { remove } from "../../utils/api";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";

type ModalDetails = {
  action: string;
  okText: string;
  callback: () => void;
};
export default function StudentAction({ student }: { student: Student }) {
  const client = useQueryClient();
  const [modalProps, setModalProps] = useState<ModalDetails>();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { mutate: doDelete, isLoading: deleting } = useMutation(remove, {
    onSuccess({ data }) {
      if (data.is_success) {
        client.invalidateQueries("students").finally(onClose);
      }

      toast(data.message, { type: data.is_success ? "success" : "error" });
    },
    onError(error) {
      if (error) {
        toast.error((error as any).response.data.message);
      }
    },
  });

  function handleShowModal(data: ModalDetails) {
    setModalProps(data);
    onOpen();
  }

  useLayoutEffect(() => {
    if (!isOpen) {
      setModalProps(undefined);
    }
  }, [isOpen]);

  return (
    <>
      <Button
        onClick={() =>
          handleShowModal({
            action: "Revoke Assigned ID",
            okText: "Revoke",
            callback: () =>
              doDelete({
                endpoint: "/students",
                query: [{ key: "mat_number", value: student.mat_number }],
              }),
          })
        }
        color="danger"
        size="sm"
        variant="flat"
        radius="sm"
      >
        Revoke
      </Button>

      <Modal
        radius="sm"
        hideCloseButton={true}
        isOpen={isOpen}
        isKeyboardDismissDisabled={true}
      >
        <ModalContent>
          <ModalHeader className="pb-0 text-xl font-bold">
            {modalProps?.action}?
          </ModalHeader>
          <ModalBody>
            <p className="text-sm">
              Are you sure you to{" "}
              <span className="text-semibold">
                {modalProps?.action.toLocaleLowerCase()}
              </span>
              ?
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              onClick={onClose}
              size="sm"
              radius="sm"
              variant="flat"
              className="font-semibold uppercase"
            >
              Cancel
            </Button>
            <Button
              size="sm"
              radius="sm"
              color="danger"
              isLoading={deleting}
              className="font-semibold uppercase"
              onPress={() => modalProps?.callback()}
            >
              {modalProps?.okText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
