import { Button, Link, Radio, RadioGroup, cn } from "@nextui-org/react";
import { greeting } from "../utils/functions";
import { useState } from "react";

function Index() {
  const [selected, setSelected] = useState<string>();

  const options: {
    title: string;
    description: string;
    props?: any & {
      value: "/student?q=new" | "/student?q=continuing" | "/admin";
    };
  }[] = [
    {
      title: "Freshmen",
      description:
        "Student who is new to the university and has not been assigned a Matriculation Number.",
      props: { value: "/student?q=freshmen" },
    },
    {
      title: "Continuing Student",
      description:
        "Student returning to continue studies with an existing Matriculation Number",
      props: { value: "/student?q=continuing-student" },
    },
    {
      title: "System Administrator",
      description:
        "Staff member of the university system or registry office with admin privileges to manage students and accounts.",
      props: { value: "/auth" },
    },
  ];

  return (
    <>
      <h1 className="text-2xl font-black text-slate-600">{greeting()},</h1>
      <section id="options" className="mt-3 flex flex-col gap-3">
        <p className="text-lg">
          <em>
            Please select which of the following best describes you to proceed.
          </em>
        </p>

        <RadioGroup
          name="role"
          onChange={({ target: { value } }) => setSelected(value)}
        >
          {options.map((option, index) => (
            <CustomRadio key={index} {...option.props}>
              <div className="flex flex-col gap-1">
                <h3 className="text-lg font-black">{option.title}</h3>
                <p className="text-sm text-slate-600">{option.description}</p>
              </div>
            </CustomRadio>
          ))}
        </RadioGroup>

        <Button
          as={Link}
          size="lg"
          radius="sm"
          type="submit"
          color="primary"
          href={selected}
          fullWidth={true}
          isDisabled={!Boolean(selected)}
          className="mt-8 font-bold uppercase tracking-wider"
        >
          click proceed
        </Button>
      </section>
    </>
  );
}
export const CustomRadio = (props: any) => {
  const { children, ...otherProps } = props;

  return (
    <Radio
      {...otherProps}
      classNames={{
        base: cn(
          "inline-flex m-0 bg-content1 hover:bg-content2 items-center justify-between",
          "flex-row-reverse max-w-full cursor-pointer rounded-lg gap-4 p-4 border-2",
          "data-[selected=true]:border-primary"
        ),
      }}
    >
      {children}
    </Radio>
  );
};

export default Index;
