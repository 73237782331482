import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  SelectItem,
} from "@nextui-org/react";
import { InputField, SelectField } from "../CustomComponents";
import { getErrorResponse, getYears } from "../../utils/functions";
import { useForm } from "react-hook-form";
import type { CreateApplicant } from "../../utils/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { CreateApplicantSchema } from "../../utils/schemas";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "react-query";
import { post } from "../../utils/api";
import { Campuses } from "../../assets/assets";
import { useEffect, useLayoutEffect } from "react";

export default function CreateSingle({
  show,
  onHide,
}: {
  show: boolean;
  onHide: () => void;
}) {
  const client = useQueryClient();
  const { mutate, error, isError, isLoading } = useMutation(
    post<CreateApplicant>,
    {
      onSuccess({ data }) {
        client
          .invalidateQueries("applicants")
          .then(() => {
            toast.success(
              <div className="flex flex-col">
                <p>
                  <strong>Student added</strong>
                </p>
                <em>Account password: {data.data.plain}</em>
              </div>
            );
          })
          .finally(onHide);
      },
    }
  );
  const {
    reset,
    register,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<CreateApplicant>({
    resolver: zodResolver(CreateApplicantSchema),
  });

  function onSubmit(data: CreateApplicant) {
    return mutate({
      data,
      endpoint: "/applicants/create",
    });
  }

  useEffect(() => {
    if (isError) {
      const response = getErrorResponse(error);

      switch (response.code) {
        case 409:
          setError("id", { message: response.message });
          break;
        default:
          toast.error(response.message);
          break;
      }
    }
  }, [isError, error, setError]);

  useLayoutEffect(reset, [reset, show]);

  return (
    <Modal
      radius="sm"
      isOpen={show}
      onClose={onHide}
      isDismissable={false}
      hideCloseButton={true}
      backdrop="blur"
      isKeyboardDismissDisabled={true}
    >
      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader className="font-bold text-2xl">
          Enroll new student
        </ModalHeader>

        <ModalBody>
          <InputField
            autoFocus={true}
            variant="bordered"
            inputMode="numeric"
            label="Application ID"
            isInvalid={Boolean(errors.id)}
            errorMessage={errors.id?.message}
            {...register("id", { required: true })}
          />

          <InputField
            variant="bordered"
            label="Full name"
            isInvalid={Boolean(errors.full_name)}
            errorMessage={errors.full_name?.message}
            {...register("full_name", { required: true })}
          />

          <InputField
            variant="bordered"
            label="Program"
            isInvalid={Boolean(errors.program)}
            errorMessage={errors.program?.message}
            {...register("program", { required: true })}
          />

          <SelectField
            radius="sm"
            variant="bordered"
            label="Academic year"
            isInvalid={Boolean(errors.academic_year)}
            errorMessage={errors.academic_year?.message}
            {...register("academic_year", { required: true })}
            items={getYears()}
          >
            {(item: any) => (
              <SelectItem key={item.value}>{item.label}</SelectItem>
            )}
          </SelectField>

          <SelectField
            radius="sm"
            variant="bordered"
            label="Campus"
            isInvalid={Boolean(errors.campus)}
            errorMessage={errors.campus?.message}
            {...register("campus", { required: true })}
            items={Campuses.map((c) => ({ label: c, value: c }))}
          >
            {(item: any) => (
              <SelectItem key={item.value}>{item.label}</SelectItem>
            )}
          </SelectField>
        </ModalBody>

        <ModalFooter>
          <Button
            onPress={onHide}
            isDisabled={isLoading || isSubmitting}
            radius="sm"
            variant="ghost"
            className="font-semibold uppercase tracking-wider"
          >
            cancel
          </Button>
          <Button
            type="submit"
            color="success"
            radius="sm"
            isLoading={isLoading || isSubmitting}
            className="font-semibold uppercase text-white tracking-wider"
          >
            enroll
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
