import { Response } from "./types";

export function getYears() {
  const years = [];
  const year = new Date().getUTCFullYear();

  for (let index = year; index > 2023; index--) {
    years.push({
      value: index - 1,
      label: `${index - 1}/${index}`,
    });
  }

  return years;
}

export function getSessionData(key: string) {
  const session = sessionStorage.getItem(key);

  return session ? JSON.parse(atob(session)) : session;
}

export function setSessionData(key: string, data: any) {
  return sessionStorage.setItem(key, btoa(JSON.stringify(data)));
}

export function unsetSessionData(key: string) {
  return sessionStorage.removeItem(key);
}

export function greeting() {
  const time = new Date().getHours();

  if (time < 12) {
    return "Good Morning";
  } else if (time < 18) {
    return "Good Afternoon";
  }

  return "Good Evening";
}

export function getErrorMessage(errors: any) {
  return getErrorResponse(errors).message;
}

export function getErrorResponse(error: any): Response {
  const response = {
    isFailure: true,
    isSuccess: true,
    code: 500,
    data: null,
    message: "An error was encountered",
  };

  if (error) {
    return (error.response?.data as Response) || response;
  }

  return response;
}
