import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@nextui-org/react";
import { InputField } from "../CustomComponents";
import { useForm } from "react-hook-form";
import type { Account, AssignAccount } from "../../utils/types";
import { zodResolver } from "@hookform/resolvers/zod";
import { AssignAccountSchema } from "../../utils/schemas";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "react-query";
import { post } from "../../utils/api";
import { useEffect, useLayoutEffect } from "react";
import { getErrorResponse } from "../../utils/functions";

export default function AssignSingle({
  show,
  onHide,
  account,
}: {
  show: boolean;
  account: Account;
  onHide: () => void;
}) {
  const client = useQueryClient();
  const { mutate, error, isError, isLoading } = useMutation(
    post<AssignAccount>,
    {
      onSuccess({ data }) {
        toast.success(data.message);
        client
          .invalidateQueries("accounts")
          .then(() => {
            toast.success(data.message);
          })
          .finally(onHide);
      },
    }
  );
  const {
    reset,
    setError,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<AssignAccount>({
    resolver: zodResolver(AssignAccountSchema),
  });

  function onSubmit(data: AssignAccount) {
    return mutate({
      data,
      endpoint: "/students",
    });
  }

  useEffect(() => {
    if (isError) {
      const response = getErrorResponse(error);
      switch (response.code) {
        case 409:
          setError("mat_number", { message: response.message });
          break;
        default:
          toast.error(response.message, { data: response.data });
          break;
      }
    }
  }, [error, isError, setError]);

  useLayoutEffect(reset, [show, reset]);

  return (
    <Modal
      radius="sm"
      isOpen={show}
      onClose={onHide}
      isDismissable={false}
      hideCloseButton={true}
      backdrop="blur"
      isKeyboardDismissDisabled={true}
    >
      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader className="text-2xl font-bold">Assign ID</ModalHeader>

        <ModalBody>
          <InputField
            autoFocus={true}
            variant="bordered"
            label="Assigned Mat Number"
            isInvalid={Boolean(errors.mat_number)}
            errorMessage={errors.mat_number?.message}
            {...register("mat_number", { required: true })}
          />

          {account && (
            <>
              <p className="text-sm text-orange-600">
                <em>
                  By clicking the assign button below, you agree to assign the
                  provided Mat Number to{" "}
                  <span className="font-semibold">{account.full_name}</span>.
                </em>
              </p>

              <input
                hidden={true}
                type="hidden"
                {...register("applicant_id", {
                  required: true,
                  value: account.id,
                })}
              />
            </>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            onPress={onHide}
            isDisabled={isLoading || isSubmitting}
            radius="sm"
            variant="ghost"
            className="font-semibold uppercase tracking-wider"
          >
            cancel
          </Button>
          <Button
            type="submit"
            color="success"
            radius="sm"
            isLoading={isLoading || isSubmitting}
            className="font-semibold uppercase text-white tracking-wider"
          >
            Assign
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
