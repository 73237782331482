import { useState } from "react";
import PageHeader from "../../components/PageHeader";
import { Button, Chip, TableCell, TableRow } from "@nextui-org/react";
import { MdRefresh } from "react-icons/md";
import { Student } from "../../utils/types";
import DataGrid from "../../components/DataGrid";
import { useQueryClient } from "react-query";
import StudentAction from "../../components/students/StudentAction";

function Students() {
  const title = "List of Generated IDs";
  const client = useQueryClient();
  const [students, setStudents] = useState<Student[]>();

  return (
    <>
      <PageHeader title={title}>
        <Button
          color="primary"
          radius="sm"
          isDisabled={students?.length === 0}
          variant="flat"
          startContent={<MdRefresh size={20} />}
          className="upper font-semibold"
          onPress={() => client.invalidateQueries("students")}
        >
          Refresh
        </Button>
      </PageHeader>

      <DataGrid
        title={title}
        queryKey="students"
        endpoint="/students/all"
        rows={students ?? []}
        columns={[
          { key: "mat_number", label: "ID/MAT" },
          { key: "applicant_id", label: "APPID" },
          { key: "full_name", label: "Full name" },
          { key: "program", label: "Program" },
          { key: "campus", label: "Campus" },
          {
            key: "is_generated",
            label: "Mode",
            props: { className: "text-start" },
          },
          {
            key: "",
            label: "",
            props: { className: "text-end" },
          },
        ]}
        renderRow={(student) => (
          <TableRow key={student.mat_number}>
            <TableCell>{student.mat_number}</TableCell>
            <TableCell>{student.applicant.id}</TableCell>
            <TableCell>{student.applicant.full_name}</TableCell>
            <TableCell>{student.applicant.program}</TableCell>
            <TableCell>{student.applicant.campus}</TableCell>
            <TableCell>
              <Chip
                size="sm"
                color={student.is_generated ? "success" : "warning"}
              >
                {student.is_generated ? "Generated" : "Assigned"}
              </Chip>
            </TableCell>
            <TableCell className="flex justify-end">
              <StudentAction student={student} />
            </TableCell>
          </TableRow>
        )}
        setData={setStudents}
      />
    </>
  );
}

export default Students;
