import {
  Button,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  SelectItem,
} from "@nextui-org/react";
import { useLayoutEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { upload } from "../../utils/api";
import { useForm } from "react-hook-form";
import { getErrorResponse, getYears } from "../../utils/functions";
import { InputField, SelectField } from "../CustomComponents";

export default function BatchUpload({
  show,
  onHide,
}: {
  show: boolean;
  onHide: () => void;
}) {
  const client = useQueryClient();
  const [file, setFile] = useState<File>();
  const { mutate, isLoading } = useMutation(upload<any>, {
    onSuccess({ data }) {
      client
        .invalidateQueries("applicants")
        .then(() => {
          toast.success(data.message);
        })
        .finally(onHide);
    },
    onError(error) {
      const response = getErrorResponse(error);
      toast.error(response.message, { data: response.data });
    },
  });
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<{ academic_year: string }>();

  function onSubmit(data: { academic_year: string }) {
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("academic_year", data.academic_year);

      return mutate({
        endpoint: "/applicants",
        data: formData,
      });
    }

    toast.error("Please select a file to upload");
  }

  useLayoutEffect(() => {
    if (!show) {
      setFile(undefined);
    }

    return reset;
  }, [show, reset]);

  return (
    <Modal
      radius="sm"
      isOpen={show}
      onClose={onHide}
      hideCloseButton={true}
      backdrop="blur"
      isDismissable={false}
      isKeyboardDismissDisabled={true}
    >
      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader className="font-bold text-2xl">
          Upload Student Data
        </ModalHeader>

        <ModalBody>
          <SelectField
            radius="sm"
            variant="bordered"
            label="Academic year"
            description="Provide the academic year students applied"
            isInvalid={Boolean(errors.academic_year)}
            errorMessage={errors.academic_year?.message}
            {...register("academic_year", { required: true })}
            items={getYears()}
          >
            {(item: any) => (
              <SelectItem key={item.value}>{item.label}</SelectItem>
            )}
          </SelectField>

          <InputField
            onChange={(e: any) =>
              e.target.files ? setFile(e.target.files[0]) : undefined
            }
            type="file"
            label="Upload file"
            accept=".csv"
            labelPlacement="outside"
            variant="bordered"
            size="md"
            classNames={{
              innerWrapper: ["mt-3"],
            }}
            placeholder="Select a file to upload"
            description={
              <>
                <span>Don't have a file? </span>
                <Link
                  href="/templates/students.csv"
                  target="_blank"
                  rel="no-referrer"
                  download="batch_upload_student_template"
                  className="text-blue-500 underline"
                >
                  download template
                </Link>
              </>
            }
          />
        </ModalBody>

        <ModalFooter>
          <Button
            onPress={onHide}
            isDisabled={isLoading || isSubmitting}
            radius="sm"
            variant="ghost"
            className="font-semibold uppercase tracking-wider"
          >
            cancel
          </Button>
          <Button
            type="submit"
            color="success"
            radius="sm"
            isLoading={isLoading || isSubmitting}
            isDisabled={!Boolean(file)}
            className="font-bold uppercase text-white tracking-wider"
          >
            upload
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
